import "./src/styles/global.scss";
export const onRouteUpdate = () => {
    var container = "#curator-feed";
    var feedId = "3bacfffb-6f3b-46d5-a1f2-1470fd023961";
    var Curator = window.Curator || {};
    var widget = new Curator.Waterfall({
        container,
        feedId,
    });
};
