// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-competitions-js": () => import("./../../../src/pages/competitions.js" /* webpackChunkName: "component---src-pages-competitions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learning-resources-js": () => import("./../../../src/pages/learning-resources.js" /* webpackChunkName: "component---src-pages-learning-resources-js" */),
  "component---src-pages-ways-to-help-js": () => import("./../../../src/pages/ways-to-help.js" /* webpackChunkName: "component---src-pages-ways-to-help-js" */),
  "component---src-pages-workshops-copy-js": () => import("./../../../src/pages/workshops copy.js" /* webpackChunkName: "component---src-pages-workshops-copy-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */),
  "component---src-templates-competition-js": () => import("./../../../src/templates/competition.js" /* webpackChunkName: "component---src-templates-competition-js" */),
  "component---src-templates-help-js": () => import("./../../../src/templates/help.js" /* webpackChunkName: "component---src-templates-help-js" */),
  "component---src-templates-learningresource-js": () => import("./../../../src/templates/learningresource.js" /* webpackChunkName: "component---src-templates-learningresource-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-workshop-js": () => import("./../../../src/templates/workshop.js" /* webpackChunkName: "component---src-templates-workshop-js" */)
}

